import React from 'react'
import Layout from "../layout/layout";
import ReactHtmlParser from "react-html-parser"
import styled from 'styled-components'
import Fade from "react-reveal/Fade"
import HomestayVideo from '../video/Homestay.mp4'
import Video from "../components/Video/LargeVideo"
import ButtonCardItem from "../components/ButtonCards/NewTabButton";
import { CardGrid, CardWrapper } from "../components/ButtonCards/CardElements";
import Logo from '../images/logo.png'
import {workwithusBanner, guardianshipApply, guardianshipContact} from '../images/'
import Accordion from "../components/Accordions/WorkWithUsHomestayFAQ";
import LargeVideo from '../components/Video/LargeVideo';
import { InsertedTestimony, WorkwithusTestimonialCarousel } from '../components/Testimonials';
import { CentreWrapper, GreenButton } from '../components/ButtonElement';
import SEO from '../components/seo';


const VideoContainer = styled.div `
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

`

const sidebar = [
  {
    to: 'agents',
    title: 'For agents'
  },
  {
    to: 'schools',
    title: 'For Schools & Universities'
  },
  {
    to: 'homestay',
    title: 'For homestays'
  },
  {
    to: 'Careers',
    title: 'Careers'
  },
]

const FadeH1 = ({ children }) => {
  return (
    <div style={{ position: "relative", textAlign: "center" }}>
      <Fade>
        <h1>{children}</h1>
      </Fade>
      <br/>
    </div>
  )
}

const Section = styled.div`
  margin: 40px 230px;
  max-width: 100%;

  @media (max-width: 1200px) {
    margin: 40px 100px;
  }

  @media (max-width: 900px) {
    margin: 40px 50px;
  }

  @media (max-width: 740px) {
    margin: 9px;
  }
`

export default function WorkWithUs ({data}) {
  const html = data.allWpPage.edges
  const workWithUs = ReactHtmlParser(html[0].node.content)
  const homestay = ReactHtmlParser(html[2].node.content)
  const homestay2 = ReactHtmlParser(html[3].node.content)
  const schools = ReactHtmlParser(html[4].node.content)
  const agents = ReactHtmlParser(html[1].node.content)
  const Careers = ReactHtmlParser(html[5].node.content)
  console.log(homestay)
  return (
      <div>
        <Layout sidebar={sidebar} pageTitle="Work with us" background={workwithusBanner} pageDescription="Our work would not be possible without our trusted partners">
          <SEO title="Work with us | Guardianship | Schools | Homestay | Agents" description="Our work would not be possible without our trusted partners. Find out more about becoming an Agent, Homestay or a school we partner with."/>
          <Section id="agents">
            <FadeH1>Agents</FadeH1>
            {agents.map((data, index) => {
            return (
              <Fade>
                <div className="no-image-data">{data}</div>
              </Fade>
            )
          })}
          </Section>
          <Section id="schools">
            <FadeH1>Schools and Universities</FadeH1>
            {schools.map((data, index) => {
            return (
              <Fade>
                <div className="no-image-data">{data}</div>
              </Fade>
            )
          })}
          </Section>
          <Section id="homestay">
            <FadeH1>Homestay</FadeH1>
            {homestay.map((data, index) => {
            return (
              <Fade>
                <div className="no-image-data">{data}</div>
              </Fade>
            )
          })}
          <CentreWrapper>
            <CardWrapper>
              <ButtonCardItem path='/homestay_application' label="Apply" text="Click here to apply to become a homestay" src={guardianshipApply}/>
            </CardWrapper>
            <CardWrapper>
              <ButtonCardItem path='/contact' label="聯繫我們" text="Got any questions? Leave us a message" src={guardianshipContact}/>
            </CardWrapper>
          </CentreWrapper>
          <InsertedTestimony quote="All the students I have hosted have been some of the nicest and warmest people I have ever come across in my life." author="Asha, Leeds"/>
          <VideoContainer>
            <Video videoSrcURL={HomestayVideo} videoTitle="Why become a homestay?"/>
          </VideoContainer>
          {homestay2.map((data, index) => {
            return (
              <Fade>
                <div className="no-image-data">{data}</div>
              </Fade>
            )
          })}
          <br/>
          <h3><strong>Homestay FAQ</strong></h3>
          <Accordion/>
          </Section>
          <Section id="Careers">
            <FadeH1>Careers</FadeH1>
            {Careers.map((data, index) => {
            return (
              <Fade>
                <div className="no-image-data">{data}</div>
              </Fade>
            )
          })}
          <CentreWrapper>
            <GreenButton href="mailto:office@studylinks.co.uk" target="_blank">Send your CV</GreenButton>
          </CentreWrapper>
          </Section>
          <WorkwithusTestimonialCarousel/>
        </Layout>
      </div>
  )
}

export const STUDYLINKS_WORKWITHUS = graphql`
  query studylinks_workwithus {
    allWpPage(filter: {title: {in: ["api-studylinks-homestays", "api-studylinks-homestays-part-2", "api-studylinks-WorkWithUs", "api-studylinks-schools&universities", "api-studylinks-agents","api-studylinks-workWithUs-Careers"]}}, sort: {fields: title, order: ASC}) {
      edges {
        node {
          title
          content
        }
      }
    }
  }

`